import { FC } from 'react'
import moment from 'moment'
import { RenderIf } from '../RenderIf'
import { useMySelector } from '../../hooks/useMySelector'
import { useTranslations } from '../../hooks/useTranslations'
import { handleLocalStorage } from '../../utils/handleLocalStorage'

import time from '../../assets/TimeLocation/time.svg'
import location from '../../assets/TimeLocation/location.svg'
import { ReactComponent as EditIcon } from '../../assets/TimeLocation/edit.svg'

import { IconStroked } from '../IconsContainers/styled'
import {
  AddressP,
  EditButton,
  ImageContainer,
  ItemContainer,
  SubItemContainer,
  SubItemTitle,
  TimeLocation_Container,
  TimeLocation_ContainerInner,
  TimeLocation_Item,
} from './styled'

type TimeLocationProps = {
  customTop: string
  editTimeLocation?: () => void
}

export const TimeLocation: FC<TimeLocationProps> = ({
  customTop,
  editTimeLocation,
}) => {
  // Hooks
  const t = useTranslations()
  const { restaurants } = useMySelector((state) => state)
  const { pickUpDelay, pickUpTime, registerId } = handleLocalStorage([
    'pickUpDelay',
    'pickUpTime',
    'registerId',
  ])

  const handleEditClick = () => {
    if (editTimeLocation) {
      editTimeLocation()
    }
  }

  const getRestTitle = () => {
    return (
      restaurants?.find((rest) => {
        return rest?.clickAndCollectClientId === registerId
      })?.title || ''
    )
  }

  const getRestAddress = () => {
    return (
      restaurants?.find((rest) => {
        return rest?.clickAndCollectClientId === registerId
      })?.address || ''
    )
  }

  return (
    <TimeLocation_Container customTop={customTop}>
      <TimeLocation_ContainerInner>
        <TimeLocation_Item isWide={!editTimeLocation}>
          <ItemContainer>
            <ImageContainer>
              <img src={time} alt="time" height="20px" />
            </ImageContainer>
            <SubItemContainer>
              <SubItemTitle>Abholdatum und Zeit:</SubItemTitle>
              <RenderIf condition={pickUpDelay}>
                <RenderIf condition={pickUpDelay === '0'}>
                  <AddressP>{`ASAP`}</AddressP>
                </RenderIf>
                <RenderIf condition={pickUpDelay !== '0'}>
                  <AddressP>{`In ${pickUpDelay} minutes after booking`}</AddressP>
                </RenderIf>
              </RenderIf>
              <RenderIf condition={!pickUpDelay && pickUpTime}>
                <AddressP>
                  {t('common.dayDateTime', {
                    dateMonth: moment(pickUpTime).format('DD MMMM'),
                    time: moment(pickUpTime).format('HH:mm'),
                  })}
                </AddressP>
              </RenderIf>
            </SubItemContainer>
          </ItemContainer>
          <ItemContainer>
            <ImageContainer>
              <img src={location} alt="location" height="20px" />
            </ImageContainer>
            <div>
              <SubItemTitle>{t('clickCollect.pickUpLocation')}:</SubItemTitle>
              <AddressP>{getRestTitle()}</AddressP>
              <AddressP>{getRestAddress()}</AddressP>
            </div>
          </ItemContainer>
        </TimeLocation_Item>
        <RenderIf condition={!!editTimeLocation}>
          <EditButton onClick={handleEditClick}>
            <IconStroked>
              <EditIcon />
            </IconStroked>
          </EditButton>
        </RenderIf>
      </TimeLocation_ContainerInner>
    </TimeLocation_Container>
  )
}
