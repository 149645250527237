import { FC, useState, useEffect } from 'react'
import { AddProductModalComponent } from './AddProductModalComponent'
import { IProduct } from '../../../../store/reducers/CategoriesReducer/types'
import { SelectedProductsSlice } from '../../../../store/reducers/SelectedProductsReducer'
import { ModalsSlice } from '../../../../store/reducers/ModalsReducer'
import { IOrderProduct } from '../../../../sections/Order/types'
import { useMyDispatch } from '../../../useMyDispatch'
import { useMySelector } from '../../../useMySelector'
import { roundValueTo } from '../../../../utils/roundValueTo'
import moment from 'moment'
import { handleLocalStorage } from '../../../../utils/handleLocalStorage'

export type KitchenInfoItemType = {
  [key: string]: string
}

type AddProductModalProps = {
  product: IProduct | IOrderProduct
  closeProduct?: () => void
  onNext?: () => void
}

export const AddProductModal: FC<AddProductModalProps> = ({
  product,
  onNext,
  closeProduct,
}) => {
  // Hooks
  const dispatch = useMyDispatch()

  // Actions
  const { addSelectedProduct } = SelectedProductsSlice.actions

  // Store
  const { selectedProducts } = useMySelector((state) => state)

  // State
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isLeadDaysExceeded, setIsLeadDaysExceeded] = useState<boolean>(false)
  const [currentAmount, setCurrentAmount] = useState<number>(1)
  const [selectedKitchenInfo, setSelectedKitchenInfo] =
    useState<KitchenInfoItemType>({})

  // useEffects
  useEffect(() => {
    setSelectedKitchenInfo(JSON.parse(product.selectedKitchenInfo || '{}'))
  }, [product?.selectedKitchenInfo])

  useEffect(() => {
    setIsDisabled(checkIsOrderDisabled())
  }, [selectedKitchenInfo])

  useEffect(() => {
    setIsLeadDaysExceeded(checkIsExceeded())
  }, [selectedKitchenInfo])

  useEffect(() => {
    const amountToSet = selectedProducts?.reduce((acc, curr) => {
      const isEqual =
        curr.id === product.id &&
        (curr.selectedKitchenInfo === product.kitchenInfo ||
          product.kitchenInfo === null)
      if (isEqual) {
        return roundValueTo(acc + curr.amount)
      } else {
        return acc
      }
    }, 0)
    setCurrentAmount(amountToSet || product.ClickCollectOrderMin || 1)
  }, [])

  // Functions
  const checkIsOrderDisabled = () => {
    // NOTE: temporary enabled for all products
    return false
    return (
      typeof product.kitchenInfo !== 'string' &&
      !!product.kitchenInfo?.length &&
      product.kitchenInfo?.length !== Object.keys(selectedKitchenInfo).length
    )
  }

  const checkIsExceeded = () => {
    const { pickUpDelay, pickUpTime } = handleLocalStorage([
      'pickUpDelay',
      'pickUpTime',
    ])

    const PickupDate = (
      pickUpTime ? moment(pickUpTime) : moment().add(pickUpDelay, 'minutes')
    ).startOf('day')

    return (
      process.env.REACT_APP_IS_CLIC === '1' &&
      !moment()
        .add(product.ClickCollectLeadDaysCount, 'days')
        .startOf('day')
        .isBefore(PickupDate)
    )
  }

  const handleKitchenInfo = (infoToAdd: KitchenInfoItemType) => {
    const [keyToChange, valueToSet] = Object.entries(infoToAdd)[0]
    setSelectedKitchenInfo((prevInfo) => {
      if (prevInfo[keyToChange]) {
        const prevValueList = prevInfo[keyToChange].split(',')
        const prevIndex = prevValueList.indexOf(valueToSet)

        if (prevIndex !== -1) {
          prevValueList.splice(prevIndex, 1)
        } else {
          prevValueList.push(valueToSet)
        }
        prevInfo[keyToChange] = prevValueList.join(',')
      } else {
        prevInfo[keyToChange] = valueToSet
      }

      if (prevInfo[keyToChange] === '') {
        delete prevInfo[keyToChange]
      }

      return { ...prevInfo }
    })
  }

  const setSelectedProduct = (
    id: number,
    addedAsWeight: boolean,
    selectedKitchenInfo: string
  ) => {
    dispatch(
      addSelectedProduct({
        id,
        uniqueId: `${id}_${JSON.stringify(selectedKitchenInfo)}`,
        addedAsWeight,
        selectedKitchenInfo,
        amount: currentAmount,
      })
    )
  }

  const onAddClick = () => {
    setSelectedProduct(
      product.id,
      product.unitSellCode === 2 || product.unitSellCode === 3,
      typeof product.kitchenInfo === 'string'
        ? product.kitchenInfo
        : JSON.stringify(selectedKitchenInfo)
    )
    closeProduct && closeProduct()
  }

  return (
    <AddProductModalComponent
      product={product}
      isAddDisabled={isDisabled}
      isLeadDaysExceeded={isLeadDaysExceeded}
      onAddClick={onAddClick}
      onNext={onNext}
      amountState={[currentAmount, setCurrentAmount]}
      selectedKitchenInfo={selectedKitchenInfo}
      handleKitchenInfo={handleKitchenInfo}
    />
  )
}

export default AddProductModal
